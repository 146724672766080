import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import hero from './../../../assets/image/rebrand/hero/reviews.png'

const HeroReviews = (props) => {
    return (
        <div className="dark-bg">
            <Container>
                <Row>
                    <Col sm={12} className="mb-30 mt-30 text-center">
                        <img src={hero} width={'70%'}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default HeroReviews;