import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { Section } from "../../components/Core";
import trustpilot5 from './../../assets/image/rebrand/svg/trustpilot-5.svg';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import TrustBoxMicroCombo from '../TrustBoxMiniCombo/TrustBoxMicroCombo';
import { navigate } from "gatsby";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

const TrustBox = ({ data }) => {
    return (
        <div className='review'>
            <div className='user-info'>
                <img className='stars' src={trustpilot5} /><br />
                <b>{data.consumer.displayName},&nbsp;</b>
                {timeAgo.format(new Date(data.createdAt), 'round')}            
            </div>
            <div className='review-content'>
                <h4>{data.title}</h4>
                <p>{data.text}</p>
            </div>
            <a className='read-more' target='_blank' href={data.reviewUrl}>
                Read more
            </a>
        </div>
    );
};

const TrustBoxReviews = ({reviews}) => {
    return (
        <Section>
            <Container>
                <TrustBoxMicroCombo />
                <small>Showing our 4 & 5 star reviews</small>
                <Row className="text-center mt-3">
                    <Col className='trustbox-grid' lg="12" xl="12">
                        {reviews ? reviews.map((review, index) => <TrustBox key={index} data={review}/>):<p>Please wait...</p>}                        
                    </Col>
                </Row>
                <Row className='justify-content-center mt-5'>
                    <Button onClick={() => navigate('https://uk.trustpilot.com/review/youfibre.com')} className="medium-button no-border green-bg mt-5">Read more</Button>
                </Row>
            </Container>
        </Section>
    );
}

export default TrustBoxReviews;