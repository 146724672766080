import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text, MainTitle } from "../components/Core";
import TrustBoxReviews from '../components/TrustBoxReviews';
import Seo from "../components/Seo/Seo";
import axios from 'axios';
import styled from "styled-components";
import HeroReviews from "../components/Rebrand/Hero/HeroReviews";
import CheckAvailability from "../components/Rebrand/CheckAvailability/CheckAvailability";
import GlobalContext from './../context/GlobalContext';

const BigTitle = styled(Title)`{
  font-size: 2.8em;
}`

const Reviews = () => {
    const [trustScore, setTrustScore] = useState(5);
    const [reviews, setReviews] = useState(null);
    const gContext = useContext(GlobalContext);
    
    useEffect(() => {
        
        if (typeof window !== 'undefined') {
            localStorage.removeItem('registrationData')
            gContext.goResetRegistrationData()
        }
        const businessUnitId = "5f4153095882480001d60ab4";
        axios.get(`https://widget.trustpilot.com/trustbox-data/53aa8912dec7e10d38f59f36?businessUnitId=${businessUnitId}&locale=en-GB&reviewLanguages=en&reviewStars=4%2C5&includeReviews=true&reviewsPerPage=20`)
            .then(function (response) {
                setReviews(response.data.reviews);
                setTrustScore(response.data.businessEntity.trustScore);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, []);

    return (
        <>
        <Seo page="reviews" />
        <HeroReviews />
        <div className="justify-content-center text-center mt-5">
            <h2 style={{ padding: '0px' }}>No, WE LOVE YOU MORE.<br />CHECK OUT OUR REVIEWS<br />& WHY WE HAVE {trustScore} STARS.</h2>
        </div>
        {reviews && <TrustBoxReviews reviews={reviews} />}
        <CheckAvailability center bg="green-bg" title={'Are we right up your street?'} />
        </>
    )
}
export default Reviews;

